<template>
    <div
        class="base-review-stars"
    >
        <div :style="{ maskImage: createGradient() }">
            <span class="base-review-stars__star">★</span>
            <span class="base-review-stars__star">★</span>
            <span class="base-review-stars__star">★</span>
            <span class="base-review-stars__star">★</span>
            <span class="base-review-stars__star">★</span>
        </div>
        <div
            class="base-review-stars__background"
        >
            <span class="base-review-stars__star">★</span>
            <span class="base-review-stars__star">★</span>
            <span class="base-review-stars__star">★</span>
            <span class="base-review-stars__star">★</span>
            <span class="base-review-stars__star">★</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    rating: {
        type: Number,
        default: 0
    }
});

const rate = computed(() => {
    const starWidth = 19/142;
    const marginWidth = 8/142;

    let width = (starWidth * 100) * props.rating;

    for (let i = 0; i < props.rating; i++) {
        width += (marginWidth * 100);
    }

    return width;
});

const createGradient = () => {
    return `linear-gradient(90deg, #000 ${rate.value}%, transparent ${rate.value}%)`;
};
</script>

<style lang="less">
.base-review-stars {
    position: relative;
    display: inline-block;
}

.base-review-stars__star {
    position: relative;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-right: .5rem;
}

.base-review-stars__background {
    position: absolute;
    top: 0;
    z-index: 1;
    opacity: .2;
}
</style>